<template>
    <img
        :src="src"
        :class="`${props.class ? props.class : 'tw-h-12 tw-w-36'}`"
        :width="width"
        :height="height"
        alt="Logo"
        v-if="src"
    />
    <img
        :src="
            settings && settings.contacts?.logo_path
                ? settings.contacts?.logo_path
                : '/img/logo.svg'
        "
        alt="logo"
        :class="`${props.class ? props.class : 'tw-h-12 tw-w-12'}`"
        :width="width"
        :height="height"
        v-else
    />
</template>

<script setup lang="ts">
/**
 *
 *  Props and emits section
 *
 *
 */

const props = defineProps<{
    height?: string
    width?: string
    class?: string
    src?: string
}>()

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

const contactsStore = useDefaultSettingsStore()
const { settings } = storeToRefs(contactsStore)

/**
 *
 *  Life cicle events
 *
 *
 */

/**
 *
 *  Functions section
 *
 *
 */
</script>
